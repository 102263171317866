import React, { useState } from "react";
import {
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Radio,
  Select,
  TimePicker,
  Tooltip,
  message,
} from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SIGN } from "../../../../config";
import moment from "moment";

const budget_date_range = {
  recommended_events: {
    date_range_type: "recommended_events",
    event_id: null,
    event_name: null,
    start_date: null,
    end_date: null,
  },
  custom_date_range: {
    date_range_type: "custom_date_range",
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: null,
  },
};

function BudgetManagement({
  handleCancel,
  getList,
  visible,
  allCampaignList,
  allCampaignListLoading,
  dropdownList,
  dropdownLoading,
  CreateRuleAction,
  fakeActionRuleManagement,
  selectedRow,
  isCloneStatus,
  isUpdateStatus,
  UpdateRuleAction,
  getRecommendedEventsAction,
  selectedMarketplace,
}) {
  const ItsView = () => Object?.keys(selectedRow)?.length !== 0;
  const [data, setData] = useState({
    marketplace_id: selectedMarketplace,
    rule_type: "budget",
    rule_status: "ACTIVE",

    campaign_list: [],
    rule_name: null,
    increased_budget_amount: null,
    increase_by_percentage: null,
    // type: 1,
    budget_rule_type: "SCHEDULE",
    budget_date_range: budget_date_range?.custom_date_range,

    recurrence: {
      type: "week",
      week_type: [
        {
          day_name: "monday",
          time: [dayjs("12:00", "HH:mm").format("HH:mm")],
        },
      ],
    },
  });

  const increased_type =
    data?.increased_budget_amount || data?.increased_budget_amount === 0
      ? "Amount"
      : "%";

  const [validations, setValidations] = useState({});
  const [eventLoading, setEventLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  const minits = ["00", "05", 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  useEffect(() => {
    if (ItsView()) {
      if (selectedRow?.campaign_list?.length > 0) {
        message.destroy();
        message.loading("Loading...", 0);
        setEventLoading(true);
        setEventList([]);
        getRecommendedEventsAction({
          marketplace_id: selectedMarketplace,
          campaign_id: Object?.keys(selectedRow?.campaign_list?.[0])?.[0],
        });
      }
      if (isCloneStatus) {
        return setData({
          ...selectedRow,
          rule_name: selectedRow?.rule_name + " - clone",
          rule_type: "budget",
        });
      }
      return setData({ ...selectedRow, rule_type: "budget" });
    }
    return () => {};
  }, []);

  const recurrenceType = {
    day_type: {
      every_days_count: 1,
      time: [
        "00:02",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
    },
    week_type: [
      {
        day_name: "monday",
        time: [dayjs("12:00", "HH:mm").format("HH:mm")],
      },
    ],
    // month_type: [
    //   {
    //     date_number: 1,
    //     time: [dayjs("10:25", "HH:mm").format("HH:mm")],
    //   },
    // ],
  };

  const onDataChange = (type, value) => {
    setData({
      ...data,
      [type]: value,
    });
  };

  const addMonthTypeObject = (newDateNumber, newTimeArray) => {
    const newMonthTypeObject = {
      date_number: newDateNumber,
      time: newTimeArray,
    };

    const updatedrecurrence = {
      ...data.recurrence,
      month_type: [...data.recurrence.month_type, newMonthTypeObject],
    };

    setData((prevData) => ({
      ...prevData,
      recurrence: updatedrecurrence,
    }));
  };

  const updateDateNumber = (index, newDateNumber) => {
    const updatedMonthType = [...data.recurrence.month_type]; // Create a copy of month_type array
    updatedMonthType[index] = {
      ...updatedMonthType[index],
      date_number: newDateNumber,
    };

    const updatedrecurrence = {
      ...data.recurrence,
      month_type: updatedMonthType,
    };

    setData((prevData) => ({
      ...prevData,
      recurrence: updatedrecurrence,
    }));
  };

  const GetRecommendedEventListResponse = useSelector(
    (state) => state.RuleManagement.GetRecommendedEventListResponse || {}
  );

  const CreateNewRuleResponse = useSelector(
    (state) => state.RuleManagement.CreateNewRuleResponse || {}
  );
  function capitalizeWords(str) {
    // Split the string into an array of words
    const words = str.split(" ");

    // Capitalize the first letter of each word and make the rest of the letters lowercase
    const capitalizedWords = words.map((word) => {
      if (word.length === 0) return ""; // Handle empty strings
      const firstLetter = word[0].toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });

    // Join the words back into a single string
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    if (CreateNewRuleResponse?.status === true) {
      message.destroy();
      message.success(CreateNewRuleResponse?.message);
      setValidations({});
      fakeActionRuleManagement("CreateNewRuleResponse");
      getList();
      handleCancel();
    } else if (CreateNewRuleResponse?.status === false) {
      try {
        message.destroy();
        if (Object.keys(CreateNewRuleResponse?.error)?.length > 0) {
          message.error(`${Object.values(CreateNewRuleResponse?.error)?.[0]}`);
        } else {
          message.error(CreateNewRuleResponse?.message || "-");
        }
        // message.error(CreateNewRuleResponse?.message);
        setValidations(CreateNewRuleResponse?.error || {});
        fakeActionRuleManagement("CreateNewRuleResponse");
      } catch (error) {}
    }
  }, [CreateNewRuleResponse]);

  const getValidations = (type) => {
    return "";
  };

  useEffect(() => {
    if (GetRecommendedEventListResponse?.status === true) {
      message.destroy();
      setEventList(GetRecommendedEventListResponse?.data || []);
      setEventLoading(false);
      fakeActionRuleManagement("GetRecommendedEventListResponse");
    } else if (GetRecommendedEventListResponse?.status === false) {
      message.destroy();
      setEventLoading(false);
      setEventList([]);
      fakeActionRuleManagement("GetRecommendedEventListResponse");
    }
  }, [GetRecommendedEventListResponse]);

  return (
    <Modal
      centered
      maskClosable={false}
      open={visible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      width={750}
      // className='custom-modal'
      footer={
        ItsView() && !isCloneStatus && !isUpdateStatus
          ? []
          : [
              <div className="modal-footer">
                <button
                  className="btn btn-light fs-7 me-3"
                  onClick={() => handleCancel()}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    message.destroy();
                    message.loading("Loading...", 0);

                    if (isUpdateStatus)
                      return UpdateRuleAction(selectedRow?.id, data);
                    return CreateRuleAction({
                      ...data,
                      marketplace_id: selectedMarketplace,
                    });
                  }}
                  type="button"
                  class="btn btn-primary fs-7"
                >
                  {isCloneStatus
                    ? "Clone Budget"
                    : isUpdateStatus
                    ? "Update Budget"
                    : "Add Budget"}
                </button>
              </div>,
            ]
      }
    >
      <div className="modal-content rule_management_budget_management">
        <div
          className="modal-header"
          style={{ position: "relative", width: "95%", top: "-20px" }}
        >
          <h2 className="modal-title fs-2 fw-bold">
            {Object?.keys(selectedRow)?.length === 0
              ? "Budget Management Add"
              : isUpdateStatus
              ? `Budget Management ${
                  selectedRow?.name || selectedRow?.rule_name || "-"
                }`
              : isCloneStatus
              ? `Budget Management Clone`
              : `Budget Management ${
                  selectedRow?.name || selectedRow?.rule_name || "-"
                }`}
          </h2>
        </div>
        <div
          className="modal-body pb-7 pt-1 cus-acc"
          style={{
            minHeight: "600px",
            maxHeight: "600px",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              pointerEvents:
                ItsView() && !isCloneStatus && !isUpdateStatus
                  ? "none"
                  : "auto",
            }}
          >
            <div>
              <div className="row mb-4 g-5 mb-5">
                <div className="col-md-12">
                  <div className="position-relative mr-4">
                    <label className="fw-bolder mb-1">
                      Rule Name
                      <small style={{ color: "red", marginLeft: 2 }}>*</small>
                    </label>
                    <Input
                      placeholder="Enter Rule Name"
                      onChange={(e) =>
                        onDataChange("rule_name", e.target.value)
                      }
                      value={data?.rule_name}
                      className="form-control fs-7"
                    />
                    <small
                      style={{
                        color: "#ff0000cc",
                      }}
                    >
                      {getValidations("rule_name")}
                    </small>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="position-relative mr-4">
                    <label className="fw-bolder mb-1">
                      Campaigns
                      <small style={{ color: "red", marginLeft: 2 }}>*</small>
                    </label>

                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      loading={allCampaignListLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip
                          overlayStyle={{
                            pointerEvents: "none",
                          }}
                          title={omittedValues.map(({ label }) => (
                            <div
                              style={{
                                paddingBottom: "7px",
                                borderBottom: "1px solid #adadad32",
                              }}
                            >
                              {label} <br />
                            </div>
                          ))}
                        >
                          <span>
                            <i className="fa fa-info-circle"></i>
                          </span>
                        </Tooltip>
                      )}
                      value={
                        data?.campaign_list &&
                        data?.campaign_list?.map((d) => Object.keys(d)?.[0])
                      }
                      onChange={(e, _) => {
                        const newArray = [];

                        _.forEach((item) => {
                          newArray.push({
                            [item.value]: item.label,
                          });
                        });
                        const selectedList = newArray;

                        if (_?.length > 0) {
                          message.destroy();
                          message.loading("Loading...", 0);
                          setEventLoading(true);
                          setEventList([]);

                          getRecommendedEventsAction({
                            marketplace_id: selectedMarketplace,
                            campaign_id: Object.keys(
                              selectedList?.[selectedList?.length - 1] || {}
                            )?.[0],
                          });
                        } else {
                          setEventList([]);
                        }
                        setData({
                          ...data,
                          campaign_list: newArray,
                          budget_date_range: {
                            ...data?.budget_date_range,
                            date_range_type: "recommended_events",

                            event_id: null,
                            event_name: null,
                          },
                        });
                      }}
                      mode="multiple"
                      maxTagCount="responsive"
                      options={allCampaignList?.map((d) => {
                        return {
                          label: d?.campaign_name?.replace("Vendo |", ""),
                          value: d?.campaign_id,
                        };
                      })}
                      placeholder="Select CampaignList"
                      style={{ width: "100%" }}
                      size="large"
                    />
                    <small
                      style={{
                        color: "#ff0000cc",
                      }}
                    >
                      {getValidations("campaign_list")}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-4" />

            <div className="separator separator-dashed my-4" />
            <div className="row mb-5">
              <div className="col-md-3 ">
                <label htmlFor className="fw-bolder mb-1">
                  Type
                </label>
              </div>
              <div className="col-md-9 ">
                <Radio.Group
                  onChange={(e) => {
                    if (e.target.value === "PERFORMANCE") {
                      return setData({
                        ...data,
                        budget_rule_type: e.target.value,
                        criteria: [
                          {
                            attribute: null,
                            operator: null,
                            value: null,
                          },
                        ],
                      });
                    }
                    const data_ = { ...data };
                    delete data_?.criteria;
                    setData({
                      ...data_,
                      budget_rule_type: e.target.value,
                    });
                  }}
                  value={data?.budget_rule_type}
                >
                  <Radio value="SCHEDULE">
                    {" "}
                    <div className="form-check-label ms-4">
                      <div className="fw-bolder fs-6 text-dark">Schedule</div>
                      <div className="text-muted">
                        Increases your budget during high-traffic times or
                        specific date ranges
                      </div>
                    </div>
                  </Radio>
                  <Radio value="PERFORMANCE">
                    <div className="form-check-label ms-4">
                      <div className="fw-bolder fs-6 text-dark">
                        Performance
                      </div>
                      <div className="text-muted">
                        Increases your budget for return on ad spend (ROAS),
                        advertising cost of sale(ACOS), click-through rate(CTR),
                        or conversion rate(CVR)
                      </div>
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="separator separator-dashed my-4" />
            <div className={`row mb-5 `}>
              <div className="col-md-3 ">
                <label htmlFor className="fw-bolder mb-1">
                  Date Range
                </label>
              </div>
              <div className="col-md-6 ">
                <div className="form-check-label ms-4">
                  <div className="fw-bolder fs-6 text-dark">
                    Recommended events
                  </div>
                  <div className="text-muted">
                    Similar campaigns increase their budget for these events.
                  </div>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onClick={(e) => e.preventDefault()}
                    options={eventList?.map((d) => {
                      return {
                        label: d?.event_label,
                        value: d?.event_id,
                        ...d,
                      };
                    })}
                    style={{ width: "100%" }}
                    value={data?.budget_date_range?.event_id}
                    onChange={(e, d) => {
                      setData({
                        ...data,
                        budget_date_range: {
                          date_range_type: "recommended_events",
                          end_date: d?.end_date,
                          event_id: d?.event_id,
                          event_name: d?.event_name,
                          start_date: d?.start_date,
                        },
                      });
                    }}
                    placeholder="Select Event"
                    loading={eventLoading}
                  />
                </div>
                <div className="row ps-4 mt-5">
                  <div className="col-6 d-grid">
                    <label>Start Date</label>

                    <DatePicker
                      allowClear={false}
                      disabledDate={(current) => {
                        return current && current < moment().startOf("day");
                      }}
                      onChange={(e) => {
                        setData({
                          ...data,
                          budget_date_range: {
                            ...data?.budget_date_range,
                            date_range_type: "custom_date_range",
                            start_date: e
                              ? dayjs(e).format("YYYY-MM-DD")
                              : null,
                          },
                        });
                      }}
                      value={
                        data?.budget_date_range?.start_date
                          ? dayjs(
                              data?.budget_date_range?.start_date,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="col-6 d-grid">
                    <label>End Date</label>
                    <DatePicker
                      allowClear
                      disabledDate={(current) => {
                        return current && current < moment().startOf("day");
                      }}
                      onChange={(e) => {
                        setData({
                          ...data,
                          budget_date_range: {
                            ...data?.budget_date_range,
                            date_range_type: "custom_date_range",
                            end_date: e ? dayjs(e).format("YYYY-MM-DD") : null,
                          },
                        });
                      }}
                      value={
                        data?.budget_date_range?.end_date
                          ? dayjs(
                              data?.budget_date_range?.end_date,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                    />
                  </div>
                </div>
                {/* <Radio.Group
                onChange={(e) => {
                  if (
                    e.target.value === "recommended_events" &&
                    data?.campaign_list?.length > 0
                  ) {
                    message.destroy();

                    message.loading("Loading...", 0);
                    setEventLoading(true);
                    setEventList([]);
                    getRecommendedEventsAction({
                      marketplace_id: selectedMarketplace,
                      campaign_id:
                        Object?.keys(
                          data?.campaign_list?.[
                            data?.campaign_list?.length - 1
                          ] || {}
                        )?.[0] || "",
                    });
                  }

                  setData((prevData) => ({
                    ...prevData,
                    budget_date_range: budget_date_range?.[e.target.value],
                  }));
                }}
                value={data?.budget_date_range?.date_range_type || null}
              >
                <Radio value="recommended_events">
                  <div className="form-check-label ms-4">
                    <div className="fw-bolder fs-6 text-dark">
                      Recommended events
                    </div>
                    <div className="text-muted">
                      Similar campaigns increase their budget for these events.
                    </div>
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onClick={(e) => e.preventDefault()}
                      options={eventList?.map((d) => {
                        return {
                          label: d?.event_label,
                          value: d?.event_id,
                          ...d,
                        };
                      })}
                      style={{ width: "100%" }}
                      value={data?.budget_date_range?.event_id}
                      onChange={(e, d) => {
                        setData({
                          ...data,
                          budget_date_range: {
                            date_range_type: "recommended_events",
                            end_date: d?.end_date,
                            event_id: d?.event_id,
                            event_name: d?.event_name,
                            start_date: d?.start_date,
                          },
                        });
                      }}
                      placeholder="Select Event"
                      loading={eventLoading}
                    />
                  </div>
                </Radio>
                <Radio value="custom_date_range">
                  <div className="form-check-label ms-4 mt-4">
                    <div className="fw-bolder fs-6 text-dark">
                      Custom Date Range
                    </div>
                    <DatePicker.RangePicker
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          budget_date_range: {
                            ...prevData.budget_date_range,
                            start_date: dayjs(e?.[0]).format("YYYY-MM-DD"),
                            end_date: dayjs(e?.[1]).format("YYYY-MM-DD"),
                          },
                        }));
                      }}
                      value={
                        data?.budget_date_range?.start_date &&
                        data?.budget_date_range?.end_date
                          ? [
                              dayjs(
                                data?.budget_date_range?.start_date,
                                "YYYY-MM-DD"
                              ),
                              dayjs(
                                data?.budget_date_range?.end_date,
                                "YYYY-MM-DD"
                              ),
                            ]
                          : []
                      }
                      size="large"
                    />
                  </div>
                </Radio>
              </Radio.Group> */}
                {/* <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  defaultValue
                  name="ss"
                  id="events"
                  defaultChecked="checked"
                />
              </div>
              <div className="form-check form-check-custom form-check-solid mt-5">
                <input
                  className="form-check-input"
                  type="radio"
                  defaultValue
                  name="ss"
                  id="Range"
                />
                <label className="form-check-label ms-4" htmlFor="Range">
                  <div className="fw-bolder fs-6 text-dark">
                    Custom Date Range
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-sm mt-2 fs-7 w-350px"
                    placeholder="Pick date rage"
                    id="kt_daterangepicker_4"
                  />
                </label>
              </div> */}
              </div>
              <div className="separator separator-dashed my-4" />
            </div>
            <div className="row g-5 mb-5 align-items-left ">
              <div className="col-md-2">
                <div>
                  <label className="fw-bolder mb-0 fs-6">Recurrence</label>
                </div>
              </div>
              <div className="col-md-10">
                {console.log(data, "data?.recurrence?.type")}
                <Radio.Group
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      recurrence: {
                        type: e.target.value,
                        [`${e.target.value}_type`]:
                          recurrenceType[`${e.target.value}_type`],
                      },
                    }));
                  }}
                  value={data?.recurrence?.type || null}
                >
                  <Radio value={"day"}>Daily</Radio>
                  <Radio value={"week"}>Weekly</Radio>
                </Radio.Group>
              </div>
              <div className="col-md-2">
                <div></div>
              </div>

              <div className="col-md-10">
                {console.log(
                  data?.recurrence?.week_type?.map((d) => d?.day_name),
                  "data?.recurrence?.type"
                )}
                {data?.recurrence?.type === "week" && (
                  <div className="row mb-5">
                    <Checkbox.Group
                      value={data?.recurrence?.week_type?.map(
                        (d) => d?.day_name
                      )}
                      onChange={(e) => {
                        setData({
                          ...data,
                          recurrence: {
                            ...data?.recurrence,
                            type: "week",
                            week_type: e?.map((r) => ({
                              day_name: r,
                              time: ["12:00"],
                            })),
                          },
                        });
                      }}
                    >
                      {[
                        { label: "Sunday", value: "sunday" },
                        { label: "Monday", value: "monday" },
                        { label: "Tuesday", value: "tuesday" },
                        {
                          label: "Wednesday",
                          value: "wednesday",
                        },
                        {
                          label: "Thursday",
                          value: "thursday",
                        },
                        { label: "Friday", value: "friday" },
                        {
                          label: "Saturday",
                          value: "saturday",
                        },
                      ]?.map((d, i) => (
                        <div className="col-4">
                          <Checkbox key={i} value={d?.value}>
                            {d?.label}
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  </div>
                )}
              </div>
            </div>
            <div className="row g-5 mb-5 align-items-center mx-5 d-none">
              <div class="col-md-2">
                <div>
                  <label className="fw-bolder mb-0 fs-6">Recurrence</label>
                </div>
              </div>
              <div class="col-3 d-flex align-items-center">
                {data?.recurrence?.type !== "day" && (
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    style={{ width: "100px", marginRight: "8px" }}
                    value={
                      data?.recurrence?.month_type?.length ||
                      data?.recurrence?.week_type?.length
                    }
                    onChange={(e) => {
                      const listSize =
                        data?.recurrence?.month_type?.length ||
                        data?.recurrence?.week_type?.length;

                      if (listSize > e) {
                        return setData((prevData) => {
                          return {
                            ...prevData,
                            recurrence: {
                              ...prevData.recurrence,
                              [`${prevData?.recurrence?.type}_type`]: [
                                ...prevData.recurrence?.[
                                  `${prevData?.recurrence?.type}_type`
                                ].slice(0, e),
                              ],
                            },
                          };
                        });
                      }
                      const copiedObjects = Array(
                        e -
                          (data?.recurrence?.month_type?.length ||
                            data?.recurrence?.week_type?.length)
                      )
                        .fill(null)
                        .map(() => ({
                          ...recurrenceType[
                            `${data?.recurrence?.type}_type`
                          ]?.[0],
                        }));

                      setData((prevData) => {
                        return {
                          ...prevData,
                          recurrence: {
                            ...prevData.recurrence,
                            [`${prevData?.recurrence?.type}_type`]: [
                              ...prevData.recurrence?.[
                                `${prevData?.recurrence?.type}_type`
                              ],
                              ...copiedObjects,
                            ],
                          },
                        };
                      });
                    }}
                    options={Array(12)
                      .fill(1)
                      ?.map((_, i) => {
                        return { label: i + 1, value: i + 1 };
                      })}
                  />
                )}
                <Select
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="fs-7"
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      recurrence: {
                        type: e,
                        [`${e}_type`]: recurrenceType[`${e}_type`],
                      },
                    }));
                  }}
                  value={data?.recurrence?.type || null}
                  options={[
                    {
                      label: "Days",
                      value: "day",
                    },
                    {
                      label: "Week",
                      value: "week",
                    },
                    // {
                    //   label: "Months",
                    //   value: "month",
                    // },
                  ]}
                />
              </div>
              {data?.recurrence?.type === "day" && (
                <div
                  className="row mt-10"
                  style={{
                    background: "#dbf4ff",
                    padding: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-12">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-2">
                        <Select
                          style={{ width: "200px" }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={data?.recurrence?.day_type?.every_days_count}
                          allowClear={false}
                          onChange={(e) => {
                            const newEveryDaysCount = e; // New value for every_days_count

                            setData((prevData) => ({
                              ...prevData,
                              recurrence: {
                                ...prevData.recurrence,
                                day_type: {
                                  ...prevData.recurrence.day_type,
                                  every_days_count: newEveryDaysCount,
                                },
                              },
                            }));
                          }}
                          options={Array(31)
                            .fill("1")
                            ?.map((_, i) => {
                              return { label: i + 1, value: i + 1 };
                            })}
                        />
                      </div>
                      <small
                        className="col-8 "
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        This rule will be executed every{" "}
                        {data?.recurrence?.day_type?.every_days_count} days on
                        the number of times time has been added
                      </small>
                    </div>
                  </div>
                  <div
                    className="col-12 mt-5"
                    style={{
                      background: "rgb(161 216 244 / 21%)",
                      padding: "20px",
                      borderRadius: "7px",
                      overflow: "auto",
                      maxHeight: "170px",
                      border: "2px dashed #a1d8f4",
                    }}
                  >
                    <div className="row">
                      {data?.recurrence?.day_type?.time?.map((w, v) => (
                        <div className="col-6 mb-2" key={v}>
                          <div className="d-flex">
                            <TimePicker
                              style={{ width: "150px" }}
                              className="me-4"
                              minuteStep={5}
                              allowClear={false}
                              format="HH:mm"
                              value={dayjs(w, "HH:mm")}
                              onChange={(e) => {
                                if (
                                  minits?.findIndex(
                                    (d) =>
                                      parseInt(d) ===
                                      parseInt(dayjs(e).format("mm"))
                                  ) === -1
                                )
                                  return message.warning("Select valid time");
                                const timeIndexToUpdate = v; // Index of the time entry to update
                                const newTimeValue = dayjs(e).format("HH:mm"); // New time value you want to set

                                setData((prevData) => {
                                  const updatedTimeArray = [
                                    ...prevData.recurrence.day_type.time,
                                  ];
                                  updatedTimeArray[timeIndexToUpdate] =
                                    newTimeValue;

                                  return {
                                    ...prevData,
                                    recurrence: {
                                      ...prevData.recurrence,
                                      day_type: {
                                        ...prevData.recurrence.day_type,
                                        time: updatedTimeArray,
                                      },
                                    },
                                  };
                                });
                              }}
                            />
                            <div className="d-flex">
                              {data?.recurrence?.day_type?.time?.length > 1 && (
                                <a
                                  onClick={() => {
                                    const timeIndexToRemove = v; // Index of the time entry to remove

                                    setData((prevData) => {
                                      const updatedTimeArray =
                                        prevData.recurrence.day_type.time.filter(
                                          (_, index) =>
                                            index !== timeIndexToRemove
                                        );

                                      return {
                                        ...prevData,
                                        recurrence: {
                                          ...prevData.recurrence,
                                          day_type: {
                                            ...prevData.recurrence.day_type,
                                            time: updatedTimeArray,
                                          },
                                        },
                                      };
                                    });
                                  }}
                                  className="btn btn-light-danger btn-sm btn-icon me-3"
                                >
                                  <i className="fa fa-minus" />
                                </a>
                              )}
                              <a
                                onClick={() => {
                                  const newTime = dayjs(
                                    "10:25",
                                    "HH:mm"
                                  ).format("HH:mm"); // New time to add

                                  setData((prevData) => {
                                    const updatedTimeArray = [
                                      ...prevData.recurrence.day_type.time,
                                      newTime,
                                    ];

                                    return {
                                      ...prevData,
                                      recurrence: {
                                        ...prevData.recurrence,
                                        day_type: {
                                          ...prevData.recurrence.day_type,
                                          time: updatedTimeArray,
                                        },
                                      },
                                    };
                                  });
                                }}
                                className="btn btn-light-primary btn-sm btn-icon me-3"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* {data?.recurrence?.type === "month" &&
              data?.recurrence?.month_type?.map((d, i) => (
                <div
                  className="row mt-10"
                  key={i}
                  style={{
                    background: "#dbf4ff",
                    padding: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-12">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-2">
                        <Select
                          style={{ width: "100%" }}
                          value={d?.date_number}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          allowClear={false}
                          onChange={(e) => updateDateNumber(i, e)}
                          options={Array(31)
                            .fill("1")
                            ?.map((_, i) => {
                              return { label: i + 1, value: i + 1 };
                            })}
                        />
                      </div>
                      <small
                        className="col-8 "
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        This rule will be executed at {d?.date_number} of every
                        month
                      </small>
                      <div
                        className="col-2"
                        style={{
                          position: "relative",
                          top: "-28px",
                          left: "10px",
                        }}
                      >
                        <div className="d-flex">
                          {data?.recurrence?.month_type?.length > 1 && (
                            <a
                              onClick={() => {
                                const monthTypeIndexToRemove = i; // Index of the month_type object to remove

                                setData((prevData) => {
                                  const updatedMonthType = [
                                    ...prevData.recurrence.month_type,
                                  ];
                                  updatedMonthType.splice(
                                    monthTypeIndexToRemove,
                                    1
                                  );

                                  return {
                                    ...prevData,
                                    recurrence: {
                                      ...prevData.recurrence,
                                      month_type: updatedMonthType,
                                    },
                                  };
                                });
                              }}
                              className="btn btn-light-danger btn-sm btn-icon me-3"
                            >
                              <i className="fa fa-minus" />
                            </a>
                          )}
                          <a
                            onClick={() => {
                              addMonthTypeObject(1, [dayjs().format("HH:mm")]);
                            }}
                            className="btn btn-light-primary btn-sm btn-icon me-3"
                          >
                            <i className="fa fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 mt-5"
                    style={{
                      background: "rgb(161 216 244 / 21%)",
                      padding: "20px",
                      borderRadius: "7px",
                      overflow: "auto",
                      maxHeight: "170px",
                      border: "2px dashed #a1d8f4",
                    }}
                  >
                    <div className="row">
                      {d?.time?.map((w, v) => (
                        <div className="col-6 mb-2" key={v}>
                          <div className="d-flex">
                            <TimePicker
                              style={{ width: "150px" }}
                              allowClear={false}
                              className="me-4"
                              minuteStep={5}
                              format="HH:mm"
                              value={dayjs(w, "HH:mm")}
                              onChange={(e) => {
                                if (
                                  minits?.findIndex(
                                    (d) =>
                                      parseInt(d) ===
                                      parseInt(dayjs(e).format("mm"))
                                  ) === -1
                                )
                                  return message.warning("Select valid time");
                                const monthTypeIndexToUpdate = i; // Index of the month_type object to update
                                const timeIndexToUpdate = v; // Index of the time entry to update
                                const newTimeValue = dayjs(e).format("HH:mm"); // New time value you want to set

                                setData((prevData) => {
                                  const updatedMonthType = [
                                    ...prevData.recurrence.month_type,
                                  ];
                                  const updatedTimeArray = [
                                    ...updatedMonthType[monthTypeIndexToUpdate]
                                      .time,
                                  ];
                                  updatedTimeArray[timeIndexToUpdate] =
                                    newTimeValue;

                                  updatedMonthType[monthTypeIndexToUpdate] = {
                                    ...updatedMonthType[monthTypeIndexToUpdate],
                                    time: updatedTimeArray,
                                  };

                                  return {
                                    ...prevData,
                                    recurrence: {
                                      ...prevData?.recurrence,
                                      month_type: updatedMonthType,
                                    },
                                  };
                                });
                              }}
                            />
                            <div className="d-flex">
                              {d?.time?.length > 1 && (
                                <a
                                  onClick={() => {
                                    const monthTypeIndexToRemove = i; // Index of the month_type object to update
                                    const timeIndexToRemove = v; // Index of the time entry to remove

                                    setData((prevData) => {
                                      const updatedMonthType = [
                                        ...prevData.recurrence.month_type,
                                      ];
                                      const updatedTimeArray = [
                                        ...updatedMonthType[
                                          monthTypeIndexToRemove
                                        ].time,
                                      ];
                                      updatedTimeArray.splice(
                                        timeIndexToRemove,
                                        1
                                      );

                                      updatedMonthType[monthTypeIndexToRemove] =
                                        {
                                          ...updatedMonthType[
                                            monthTypeIndexToRemove
                                          ],
                                          time: updatedTimeArray,
                                        };

                                      return {
                                        ...prevData,
                                        recurrence: {
                                          ...prevData.recurrence,
                                          month_type: updatedMonthType,
                                        },
                                      };
                                    });
                                  }}
                                  className="btn btn-light-danger btn-sm btn-icon me-3"
                                >
                                  <i className="fa fa-minus" />
                                </a>
                              )}
                              <a
                                onClick={() => {
                                  const currentTime = dayjs(
                                    "10:25",
                                    "HH:mm"
                                  ).format("HH:mm");

                                  setData((prevData) => {
                                    const updatedMonthType = [
                                      ...prevData.recurrence.month_type,
                                    ];
                                    updatedMonthType[i].time.push(currentTime);

                                    return {
                                      ...prevData,
                                      recurrence: {
                                        ...prevData.recurrence,
                                        month_type: updatedMonthType,
                                      },
                                    };
                                  });
                                }}
                                className="btn btn-light-primary btn-sm btn-icon me-3"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))} */}

              {data?.recurrence?.type === "week" &&
                data?.recurrence?.week_type?.map((d, i) => (
                  <div
                    className="row mt-10"
                    key={i}
                    style={{
                      background: "#dbf4ff",
                      padding: "10px",
                      borderRadius: "7px",
                    }}
                  >
                    <div className="col-12">
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="col-2">
                          <Select
                            style={{ width: "100%" }}
                            value={d?.day_name}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            allowClear={false}
                            onChange={(e) => {
                              const updatedMonthType = [
                                ...data.recurrence.week_type,
                              ]; // Create a copy of week_type array
                              updatedMonthType[i] = {
                                ...updatedMonthType[i],
                                day_name: e,
                              };

                              const updatedrecurrence = {
                                ...data.recurrence,
                                week_type: updatedMonthType,
                              };

                              setData((prevData) => ({
                                ...prevData,
                                recurrence: updatedrecurrence,
                              }));
                            }}
                            options={[
                              { label: "Sunday", value: "sunday" },
                              { label: "Monday", value: "monday" },
                              { label: "Tuesday", value: "tuesday" },
                              { label: "Wednesday", value: "wednesday" },
                              { label: "Thursday", value: "thursday" },
                              { label: "Friday", value: "friday" },
                              { label: "Saturday", value: "saturday" },
                            ]}
                          />
                        </div>
                        <small
                          className="col-8 "
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          This rule will be executed at {d?.day_name} of every
                          week
                        </small>
                        <div
                          className="col-2"
                          style={{
                            position: "relative",
                            top: "-28px",
                            left: "10px",
                          }}
                        >
                          <div className="d-flex">
                            {data?.recurrence?.week_type?.length > 1 && (
                              <a
                                onClick={() => {
                                  const monthTypeIndexToRemove = i; // Index of the week_type object to remove

                                  setData((prevData) => {
                                    const updatedMonthType = [
                                      ...prevData.recurrence.week_type,
                                    ];
                                    updatedMonthType.splice(
                                      monthTypeIndexToRemove,
                                      1
                                    );

                                    return {
                                      ...prevData,
                                      recurrence: {
                                        ...prevData.recurrence,
                                        week_type: updatedMonthType,
                                      },
                                    };
                                  });
                                }}
                                className="btn btn-light-danger btn-sm btn-icon me-3"
                              >
                                <i className="fa fa-minus" />
                              </a>
                            )}
                            <a
                              onClick={() => {
                                const newMonthTypeObject = {
                                  day_name: "monday",
                                  time: [dayjs().format("HH:mm")],
                                };

                                const updatedrecurrence = {
                                  ...data.recurrence,
                                  week_type: [
                                    ...data.recurrence.week_type,
                                    newMonthTypeObject,
                                  ],
                                };

                                setData((prevData) => ({
                                  ...prevData,
                                  recurrence: updatedrecurrence,
                                }));
                              }}
                              className="btn btn-light-primary btn-sm btn-icon me-3"
                            >
                              <i className="fa fa-plus" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-12 mt-5"
                      style={{
                        background: "rgb(161 216 244 / 21%)",
                        padding: "20px",
                        borderRadius: "7px",
                        overflow: "auto",
                        maxHeight: "170px",
                        border: "2px dashed #a1d8f4",
                      }}
                    >
                      <div className="row">
                        {d?.time?.map((w, v) => (
                          <div className="col-6 mb-2" key={v}>
                            <div className="d-flex">
                              <TimePicker
                                style={{ width: "150px" }}
                                minuteStep={5}
                                className="me-4"
                                allowClear={false}
                                format="HH:mm"
                                value={dayjs(w, "HH:mm")}
                                onChange={(e) => {
                                  const monthTypeIndexToUpdate = i; // Index of the week_type object to update
                                  const timeIndexToUpdate = v; // Index of the time entry to update
                                  const newTimeValue = dayjs(e).format("HH:mm"); // New time value you want to set

                                  setData((prevData) => {
                                    const updatedMonthType = [
                                      ...prevData.recurrence.week_type,
                                    ];
                                    const updatedTimeArray = [
                                      ...updatedMonthType[
                                        monthTypeIndexToUpdate
                                      ].time,
                                    ];
                                    updatedTimeArray[timeIndexToUpdate] =
                                      newTimeValue;

                                    updatedMonthType[monthTypeIndexToUpdate] = {
                                      ...updatedMonthType[
                                        monthTypeIndexToUpdate
                                      ],
                                      time: updatedTimeArray,
                                    };

                                    return {
                                      ...prevData,
                                      recurrence: {
                                        ...prevData?.recurrence,
                                        week_type: updatedMonthType,
                                      },
                                    };
                                  });
                                }}
                              />
                              <div className="d-flex">
                                {d?.time?.length > 1 && (
                                  <a
                                    onClick={() => {
                                      const monthTypeIndexToRemove = i; // Index of the week_type object to update
                                      const timeIndexToRemove = v; // Index of the time entry to remove

                                      setData((prevData) => {
                                        const updatedMonthType = [
                                          ...prevData.recurrence.week_type,
                                        ];
                                        const updatedTimeArray = [
                                          ...updatedMonthType[
                                            monthTypeIndexToRemove
                                          ].time,
                                        ];
                                        updatedTimeArray.splice(
                                          timeIndexToRemove,
                                          1
                                        );

                                        updatedMonthType[
                                          monthTypeIndexToRemove
                                        ] = {
                                          ...updatedMonthType[
                                            monthTypeIndexToRemove
                                          ],
                                          time: updatedTimeArray,
                                        };

                                        return {
                                          ...prevData,
                                          recurrence: {
                                            ...prevData.recurrence,
                                            week_type: updatedMonthType,
                                          },
                                        };
                                      });
                                    }}
                                    className="btn btn-light-danger btn-sm btn-icon me-3"
                                  >
                                    <i className="fa fa-minus" />
                                  </a>
                                )}
                                <a
                                  onClick={() => {
                                    const currentTime = dayjs(
                                      "10:25",
                                      "HH:mm"
                                    ).format("HH:mm");

                                    setData((prevData) => {
                                      const updatedMonthType = [
                                        ...prevData.recurrence.week_type,
                                      ];
                                      updatedMonthType[i].time.push(
                                        currentTime
                                      );

                                      return {
                                        ...prevData,
                                        recurrence: {
                                          ...prevData.recurrence,
                                          week_type: updatedMonthType,
                                        },
                                      };
                                    });
                                  }}
                                  className="btn btn-light-primary btn-sm btn-icon me-3"
                                >
                                  <i className="fa fa-plus" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="separator separator-dashed my-4" />
            {data?.budget_rule_type === "PERFORMANCE" && (
              <>
                <div className="row mb-5 align-items-center">
                  <div className="col-md-3 ">
                    <label className="fw-bolder mb-1">Performance Metric</label>
                  </div>
                  <div className="col-md-9 ">
                    <div className="d-flex flex-wrap gap-3">
                      <Select
                        style={{ width: "150px" }}
                        allowClear
                        placeholder="Select Attributes"
                        value={data?.criteria?.[0]?.attribute}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        loading={dropdownLoading}
                        onChange={(e) => {
                          const updatedCriteria = [...data.criteria]; // Create a copy of the criteria array
                          updatedCriteria[0].attribute = e; // Update the attribute

                          setData((prevData) => ({
                            ...prevData,
                            criteria: updatedCriteria, // Update the criteria array in the copied data
                          }));
                        }}
                        options={Object.entries(
                          dropdownList?.budget_rule_attributes_dd_data || {}
                        )?.map((d) => {
                          return { label: d?.[1], value: d?.[0] };
                        })}
                      />
                      <Select
                        style={{ width: "150px" }}
                        allowClear
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Select Operators"
                        value={data?.criteria?.[0]?.operator}
                        onChange={(e) => {
                          const updatedCriteria = [...data.criteria]; // Create a copy of the criteria array
                          updatedCriteria[0].operator = e; // Update the attribute

                          setData((prevData) => ({
                            ...prevData,
                            criteria: updatedCriteria, // Update the criteria array in the copied data
                          }));
                        }}
                        options={Object.entries(
                          dropdownList?.budget_rule_operators_dd_data || {}
                        )?.map((d) => {
                          return { label: d?.[1], value: d?.[0] };
                        })}
                      />
                      <div className="input-group input-group-sm w-150px">
                        <input
                          type="text"
                          className="form-control fs-7 border-end-0"
                          placeholder="Daily Budget"
                          value={data?.criteria?.[0]?.value}
                          onChange={(e) => {
                            const updatedCriteria = [...data.criteria]; // Create a copy of the criteria array
                            updatedCriteria[0].value = e.target.value; // Update the attribute

                            setData((prevData) => ({
                              ...prevData,
                              criteria: updatedCriteria, // Update the criteria array in the copied data
                            }));
                          }}
                        />
                        <span
                          className="input-group-text bg-gray-200"
                          id="basic-addon2"
                        >
                          <b className="fw-bolder">%</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed my-4" />
              </>
            )}

            <div className="row mb-1 align-items-center">
              <div className="col-md-3 ">
                <label className="fw-bolder mb-1">
                  Increase{" "}
                  <small style={{ color: "red", marginLeft: 2 }}>*</small>
                </label>
              </div>
              <div className="col-md-9 ">
                <div className="row align-items-center">
                  <div className="col-md-5 ">
                    <Input
                      value={
                        data?.[
                          increased_type !== "%"
                            ? "increased_budget_amount"
                            : "increase_by_percentage"
                        ] || ""
                      }
                      style={{ minWidth: "100px" }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[0-9]*\.?[0-9]*$/;
                        if (regex.test(inputValue)) {
                          setData({
                            ...data,
                            [increased_type === "%"
                              ? "increased_budget_amount"
                              : "increase_by_percentage"]: null,
                            [increased_type !== "%"
                              ? "increased_budget_amount"
                              : "increase_by_percentage"]:
                              parseFloat(inputValue),
                          });
                        }
                      }}
                      addonAfter={
                        <Select
                          value={increased_type}
                          onChange={(e) => {
                            console.log(
                              {
                                ...data,
                                increase_by_percentage:
                                  e === "Amount" ? null : 0,
                                increased_budget_amount: e === "%" ? null : 0,
                              },
                              "eeeeeeeeeee"
                            );

                            setData({
                              ...data,
                              increase_by_percentage: e === "Amount" ? null : 0,
                              increased_budget_amount: e === "%" ? null : 0,
                            });
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          className="w-100px"
                        >
                          <Select.Option value="%">%</Select.Option>
                          <Select.Option value="Amount">Amount</Select.Option>
                        </Select>
                      }
                      placeholder="Daily Budget"
                    />
                  </div>
                  {/* <div className="col-md-6 ">
                  A {SIGN}250.00 daily budget will be {SIGN}395.00 for this rule
                </div> */}
                </div>
              </div>
            </div>

            {data?.budget_date_range?.date_range_type !==
              "custom_date_range" && (
              <div className="row">
                <div className="col-md-5 offset-md-3">
                  Recommended:{" "}
                  <span className="text-primary">
                    +
                    {eventList?.filter(
                      (d) => d?.event_id === data?.budget_date_range?.event_id
                    )?.[0]?.suggested_budget_increase_percent || 0}
                    %
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    </Modal>
  );
}

export default BudgetManagement;
